





































import { Component, Prop } from 'vue-property-decorator';
import TabMenuItemParams from '@/utils/types/TabMenuItemParams';
import TabMenuItemComponent from '@/components/TabMenuItemComponent.vue';
import VueStickyWidget from '@/utils/widgets/VueStickyWidget';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { FeatureKeys } from '@/utils/enums/FeatureKeys';
import SlotTypeEnum from '@/utils/enums/SlotTypeEnum';
import { Getter, namespace } from 'vuex-class';
import CommunityFeature from '@/models/graphql/CommunityFeature';

const salesPackageLimitStore = namespace('SalesPackageLimitStore');

@Component({
  components: { TabMenuItemComponent },
})
export default class TabMenuComponent extends mixins(VueStickyWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'TabMenuWidgetStore';

  @Getter
  protected featureByKey!: (key: FeatureKeys) => CommunityFeature;

  @Prop({
    required: false,
    default: () => [],
  })
  private tabConfigs!: TabMenuItemParams[];

  @Prop({
    required: false,
    default: 'center',
  })
  private alignment!: string;

  @Prop({
    required: false,
    default: false,
  })
  private isPrivate!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private isUserPrivate!: boolean;

  @salesPackageLimitStore.Action
  private filterSlots!: (payload: { exhibitorUid: string; code: string })
      => Promise<{ type: string; limit: number; code: number }[]>;

  private allowCompanyMeetingTab = true;

  private get direction(): string {
    // eslint-disable-next-line no-nested-ternary
    return this.alignment === 'center'
      ? 'justify-content-center'
      : this.alignment === 'left'
        ? 'justify-content-start'
        : 'justify-content-end';
  }

  private get allItemsPrivate(): boolean {
    return this.tabConfigs.filter((c) => c.isUserPrivate).length === this.tabConfigs.length;
  }

  private get isCompanyMeetingFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)
        && this.featureByKey(FeatureKeys.COMMUNITY_ENABLE_COMPANY_MEETING)?.enabled;
  }

  private get isNewLimitCalculationFeatureEnabled(): boolean {
    return this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)
        && this.featureByKey(FeatureKeys.COMMUNITY_NEW_LIMITS_CALCULATION_FEATURE)?.enabled;
  }

  created(): void {
    this.setDataConfig();
    this.accessVerificationForCompanyMeetingTab();
    this.stickyElementId = `tab-menu-${this.widget.id}`;
    this.stickyElementWrapperId = `tab-menu-wrapper-${this.widget.id}`;
  }

  savePosition(): void {
    if (this.stickyElement && this.wrapper && this.tabMenuHeader) {
      delete this.stickyElement.style.top;
      this.stickyElement.classList.remove('shadows-md');
    }
  }

  private accessVerificationForCompanyMeetingTab(): void {
    const foundMeetingRequestPage = this.tabConfigs.find((tab) => tab.route === 'allowCompanyMeetingTab');
    if (this.isCompanyMeetingFeatureEnabled && foundMeetingRequestPage) {
      const { companyId } = this.$route.params;
      if (companyId && this.isNewLimitCalculationFeatureEnabled) {
        this.filterSlots({
          exhibitorUid: companyId,
          code: SlotTypeEnum.MEETING_FEATURE,
        })
          .then((response) => {
            if (response && response[0]) {
              this.allowCompanyMeetingTab = response[0].limit === -1 || response[0].limit > 0;
            } else {
              this.allowCompanyMeetingTab = false;
            }
          });
      } else {
        this.allowCompanyMeetingTab = true;
      }
    } else {
      this.allowCompanyMeetingTab = false;
    }
  }
}
